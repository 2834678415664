import React from 'react';
import moment from 'moment';
import { EllipsisVerticalIcon } from '@heroicons/react/24/solid';

export default {
  formatFromTarget: target => {
    let value;
    switch (target.type) {
      case 'checkbox':
        value = target.checked;
        break;
      case 'number':
        value = Number(target.value);
        break;
      case 'react-datetime':
        value = moment(target.value).toISOString();
        break;
      default:
        value = target.value;
    }
    return value;
  },

  formatDateForAPI: date => {
   // return moment(date).format('YYYY-MM-DD')
   // return moment(date).format('YYYY-MM-DD HH:mm:ss.SSSSSS');
   return moment(date).endOf('day').toISOString();
    //return moment.utc(date).local().format('YYYY-MM-DD HH:mm:ss.SSSSSS');
  //  const baseFormat = moment(date).format('YYYY-MM-DD HH:mm:ss.SSS');
    //return `${baseFormat}036`;
    //return moment(date).toISOString(); //.format('YYYY-MM-DDTHH:mm:ss.sss');
  },

  formatDate: date => {
    return moment(date).format('DD/MM/YYYY');
  },

  formatDateTime: date => {
    return moment(date).format('DD/MM/YYYY HH:mm:ss');
  },

  formatNumberDecimals: (number, decimals = 5) => {
    const decimalPlaces = 10 ** decimals;
    number = Math.round(number * decimalPlaces) / decimalPlaces;
    let parts = number.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    return parts.join(',');
  },

  roomsOptions: data => {
   
    let infoRooms;
    if (data == 'null a null' || data == 'NULL') {
      return infoRooms = '*';
    } 
    if (data == '1 a 1') {
   
      return infoRooms = '1';
    } 
    if (data == '2 a 2') {
      return infoRooms = '2';
    } 
    if (data == '3 a 3') {
   
      return infoRooms = '3';
    }
    if (data == '4 a 4') {
      return infoRooms = '4';
    } 
    if (data == '5 a 5') {
      return infoRooms = '5';
    }
    
      infoRooms = data?.replace(/\samb\.$/i, '');
 
    return infoRooms;
  },


  replaceWord:data=>{
    let newDeliveryText;
    if (data?.toLowerCase().includes("entrega")) {
     
       newDeliveryText= data.replace(/entrega/gi, "POSESIÓN");
       newDeliveryText=newDeliveryText.toUpperCase();
      return newDeliveryText ;
  }
},

formatPossessionDate: (dateString)=> {
  const months = [
    "ENERO", "FEBRERO", "MARZO", "ABRIL", "MAYO",
    "JUNIO", "JULIO", "AGOSTO", "SEPTIEMBRE",
    "OCTUBRE", "NOVIEMBRE", "DICIEMBRE"
  ];

 
  const [year, month] = dateString.split("-");


  const monthName = months[parseInt(month, 10) - 1];


  return `POSESIÓN ${monthName} ${year}`;
},


formatUSD:data=>{
  let usdFormat=data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  return usdFormat;

},

formatARS:data => {
  // Asegurar que el número tenga dos decimales
  let number = Number(data).toFixed(2);
  
  // Reemplazar el punto por coma para los decimales
  let parts = number.split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  
  return parts.join(',');
},
capitalizeFirstLetter :(str) => {
  if (!str) return str; 
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
},



formattAntiquity:data=>{
  {
    switch (data) {
      case -1:
        return 'En construcción';
      default:
        return `${data} años`;
    }
  };
},


cleanLocation : (location) => {
  return location?.replace(/^\|\s*|\s*\|$/g, '').trim();
},
cleanLocationUppercase : (location) => {
  return location?.toUpperCase().replace(/^\|\s*|\s*\|$/g, '').trim();
},

formatTypology: (typology) => {
  
  const numbers = typology?.replace(/,\s*$/, '').split(',').map(num => num.trim()).filter(Boolean);
   
  const formatted = numbers
    .map(Number)
    .sort((a, b) => a - b)
    .map(num => `${num} AMB.`);

  return formatted.join(' | ');
},

getLocationForMetaTags:(fullLocation) => {
  if (!fullLocation) return ""; 
  const parts = fullLocation.split("|");
  return parts[3]?.trim() || ""; 
},
getLocationForMetaTagsZone:(fullLocation) => {
  if (!fullLocation) return ""; 
  const parts = fullLocation.split("|");
  return parts[2]?.trim() || ""; 
},
getLocationForMetaTagsCity:(fullLocation) => {
  if (!fullLocation) return ""; 
  const parts = fullLocation.split("|");
  return parts[1]?.trim() || ""; 
},

 getPrincipalPriceLabel: (property) => {
  if (!property?.saleAmountUSD && !property?.rentAmountUSD && !property?.rentAmountARS) {
    return 'CONSULTAR';
  }

  if (property?.saleAmountUSD ) {
    return `U$S ${ property.saleAmountUSD.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")} `
   
  } 
  
  if (!property?.saleAmountUSD && property?.rentAmountUSD) {
    return `U$S ${ property.rentAmountUSD.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")} `
  }
  if (!property?.saleAmountUSD && !property?.rentAmountUSD && property?.rentAmountARS) {
    return `$ ${property.rentAmountARS.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")} `
  }
 },

 getSecondaryPriceLabel: (property) => {
  if (property?.saleAmountUSD && property?.rentAmountUSD || property?.saleAmountUSD && property?.rentAmountARS) {
   if(property?.rentAmountUSD && property?.rentAmountARS){
    return ` U$S ${ property.rentAmountUSD.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") } || $ ${property.rentAmountARS}.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")`
   }
   if(property?.rentAmountUSD && !property?.rentAmountARS){
    return ` U$S ${ property?.rentAmountUSD.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }`
   }
   if(property?.rentAmountARS && !property?.rentAmountUSD){
    return ` $ ${property?.rentAmountARS.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }`
   }

  }
  else{
    return
  }


 },


changePropertyType:(propertyType)=>{
  const propertyTypes = {
    'Casa': 'SingleFamilyResidence',
    'Departamento': 'Apartment',
    'PH': 'MultiFamilyResidence',
    'Cochera': 'ParkingFacility',
    'Local': 'CommercialProperty',
    'Oficina': 'OfficeBuilding',
    'Depósito': 'StorageFacility',
    'Terreno': 'Land',
    'Campo': 'Farm',
    'Quinta': 'Farm',
    'Edificio comercial': 'CommercialBuilding',
    'Emprendimiento':'RealEstateProject'
  };

  return propertyTypes[propertyType] || 'Other';
}

}

